import logo from "./logo.svg";
import "./App.css";
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./views/login";
import Dashboard from "./views/dashboard";
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('53c64a6a4f48e680f4722d325f6ac0a1Tz01NDgyMyxFPTE3MDA3MzU0NzYxMzAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />}/>
            <Route exact path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Router>
      </div>
    );
  }
}
  
export default App;
