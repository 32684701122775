import "../css/nav.css";
import React, { Component, Fragment, useState, useCallback } from "react";
import logo from "../components/img/logo.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import {
  DataGridPremium,
  GridColumns,
  GridRowsProp,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport,
  GridApi,
} from "@mui/x-data-grid-premium";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import FormLabel from "@mui/material/FormLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import searchIcon from "../components/img/searchIcon.svg";
import dayjs, { Dayjs } from "dayjs";
import { Modal, Typography } from "@mui/material";

function Dashboard() {
  const [startDate, setStartDate] = useState(Dayjs);
  const [endDate, setEndDate] = useState();
  const [dataCat, setDataCat] = useState();
  const [boeList, setBoeList] = useState();
  const [boeSelected, setBoeSelected] = useState();
  const [dataBoe, setDataBoe] = useState();
  const [filt, setFilt] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalBoe, setModalBoe] = useState();
  const [moreDetails, setMoreDetails] = useState();
  const config = {
    withCredentials: true,
  };
  const apiRef = useGridApiRef();
  const modalCols = [
    { field: "client_name", headerName: "Client", width: 200 },
    { field: "date_validated", headerName: "Date Validated", width: 120 },
    { field: "bill_of_entry", headerName: "Bill of Entry", width: 120 },
    { field: "reception_no", headerName: "Reception no.", width: 120 },
    { field: "container_no", headerName: "Container no.", width: 120 },
    { field: "hs_code", headerName: "HS Code", width: 120 },
    { field: "product_code", headerName: "Product Code", width: 150 },
    { field: "description", headerName: "Product Description", width: 150 },
    { field: "REC_QTY", headerName: "Reception Qty", width: 120 },
    { field: "DEL_QTY", headerName: "Delivered Qty", width: 120 },
    { field: "STCK_QTY", headerName: "Stock Qty", width: 120 },
    { field: "delivery_no", headerName: "Delivery no.", width: 120 },
    { field: "datesortie", headerName: "Delivery Date", width: 120 },
  ];

  const columns = [
    { field: "bill_of_entry", headerName: "Bill of Entry", hide: true },
    { field: "client_name", headerName: "Client Name", width: "4", hide: true },
    { field: "HSTOT", headerName: "HS Codes in Bill of Entry", width: "200" },
    {
      field: "REC_QTY",
      headerName: "Reception Quantity in Units",
      width: "220",
    },
    {
      field: "DEL_QTY",
      headerName: "Delivery Quantity in Units",
      width: "220",
    },
    { field: "STCK_QTY", headerName: "Stock Quantity in Units", width: "220" },

    {
      field: "details",
      headerName: "More Details",
      sortable: false,
      width: "235",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          console.log(JSON.stringify(thisRow, null, 4));
          setModalBoe(JSON.stringify(thisRow?.["bill_of_entry"], null, 4));
          getMoreDetails(JSON.stringify(thisRow?.["bill_of_entry"], null, 4));
          return handleOpen();
        };
        const api: GridApi = params.api;
        const thisRow: Record<string, GridCellValue> = {};
        //console.log(api.getAllColumns().filter((c) => c.type == "string" && !!c))
        return <Button onClick={onClick}>More Details</Button>;
      },
    },
  ];
  console.log(modalBoe);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1700,
    height: 800,
    bgcolor: "background.paper",
    zIndex: "1",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleLogout = () => {
    axios
      .get("http://165.232.100.195:5120/api/auth/signout", { withCredentials: true })
      .then(function (response) {
        alert(response.data.message);
        if (response.status == 200) {
          window.location.replace("/");
        }
      });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const onRowClick = useCallback(
    function (params) {
      var rowNode = apiRef.current.getRowNode(params.id);

      if (rowNode && rowNode.isAutoGenerated) {
        apiRef.current.setRowChildrenExpansion(
          params.id,
          !rowNode.childrenExpanded
        );
      }
    },
    [apiRef]
  );

  function onChangeStartDate(e) {
    var startDateValue = e.$d;
    var year = startDateValue.toLocaleString("default", { year: "numeric" });
    var month = startDateValue.toLocaleString("default", { month: "numeric" });
    var day = startDateValue.toLocaleString("default", { day: "numeric" });
    if (day < 10) {
      day = 0 + day;
    }
    if (month < 10) {
      month = 0 + month;
    }
    startDateValue = year + month + day;
    setStartDate(startDateValue);
  }

  function onChangeEndDate(e) {
    var endDateValue = e.$d;
    //console.log(e.$d.getDay());
    var year = endDateValue.toLocaleString("default", { year: "numeric" });
    var month = endDateValue.toLocaleString("default", { month: "numeric" });
    var day = endDateValue.toLocaleString("default", { day: "numeric" });
    if (day < 10) {
      day = 0 + day;
    }
    if (month < 10) {
      month = 0 + month;
    }
    endDateValue = year + month + day;
    setEndDate(endDateValue);
  }

  function handleRadio(e) {
    setDataCat(e.target.value);
    //getBoeList(e.target.value);
    //getClientList(e.target.value);
  }

  function handleSearchBy(e) {
    if (e.target.value === "bill_of_entry") {
      const boeList = document.getElementById("boe-list");
      const searchBy = document.getElementById("search-by");
      const reset = document.getElementById("reset");
      reset.style.display = "block";
      searchBy.style.display = "none";
      boeList.style.display = "block";
      getBoeList(dataCat);
    }
    if (e.target.value === "client") {
      const searchBy = document.getElementById("search-by");
      const clientList = document.getElementById("client-list");
      const reset = document.getElementById("reset");
      reset.style.display = "block";
      clientList.style.display = "block";
      searchBy.style.display = "none";
      getClientList(dataCat);
    }
    if (e.target.value === "hs_code") {
      const searchBy = document.getElementById("search-by");
      const hsList = document.getElementById("hs-list");
      const reset = document.getElementById("reset");
      reset.style.display = "block";
      searchBy.style.display = "none";
      hsList.style.display = "block";
      getHsList(dataCat);
    }
  }

  function reset() {
    const boeList = document.getElementById("boe-list");
    const searchBy = document.getElementById("search-by");
    const clientList = document.getElementById("client-list");
    const hsList = document.getElementById("hs-list");
    const reset = document.getElementById("reset");
    reset.style.display = "none";
    searchBy.style.display = "block";
    boeList.style.display = "none";
    clientList.style.display = "none";
    hsList.style.display = "none";
  }

  function getMoreDetails(ref) {
    axios
      .get("http://165.232.100.195:5120/api/customs_data/details/" + ref, config)
      .then(function (response) {
        console.log(response.data);
        setMoreDetails(response?.data.data[0]);
      });
  }

  console.log(moreDetails);

  function getBoeList(category) {
    axios
      .get(
        "http://165.232.100.195:5120/api/customs_data/" +
          category +
          "/boe/" +
          startDate +
          "/" +
          endDate,
        config
      )
      .then(function (response) {
        setBoeList(response.data);
      });
  }

  function getClientList(category) {
    axios
      .get(
        "http://165.232.100.195:5120/api/customs_data/" +
          category +
          "/clients/" +
          startDate +
          "/" +
          endDate,
        config
      )
      .then(function (response) {
        console.log(response.data);
        setBoeList(response.data);
      });
  }

  function getHsList(category) {
    axios
      .get(
        "http://165.232.100.195:5120/api/customs_data/" +
          category +
          "/hsList/" +
          startDate +
          "/" +
          endDate,
        config
      )
      .then(function (response) {
        setBoeList(response.data);
      });
  }

  console.log(boeList?.data);
  function handleBoeSelect(e: SelectChangeEvent) {
    var target = JSON.stringify(e.target);
    //var table = document.getElementById("customs-table");
    setBoeSelected(target);
    console.log(e.target);
  }

  function search() {
    console.log(boeSelected);
    if (!boeSelected) {
      axios
        .get(
          "http://165.232.100.195:5120/api/customs_data/" +
            dataCat +
            "/" +
            startDate +
            "/" +
            endDate,
          config
        )
        .then(function (response) {
          console.log(response.data.data);
          setDataBoe(response.data.data[0]);
        });
    } else {
      axios
        .get(
          "http://165.232.100.195:5120/api/customs_data/" +
            dataCat +
            "/" +
            startDate +
            "/" +
            endDate +
            "/" +
            boeSelected,
          config
        )
        .then(function (response) {
          //console.log(response);
          setDataBoe(response.data.data[0]);
          console.log(response.data.data);
          const d = response.data.data;
        });
    }
  }

  function logOut() {
    localStorage.removeItem("jwtToken");
    window.location.replace("/");
  }

  const color = "black";

  console.log(dataBoe);

  return (
    <div className="Dashboard">
      <div className="nav-container">
        <a href="/dashboard">
          <img className="nav-logo" src={logo} />
        </a>
        <Button
          className="nav-button"
          variant="contained"
          style={{ borderRadius: "15px" }}
        >
          Customs Dashboard
        </Button>
        <a className="nav-help-link" href="https://mfd.deskpro.com/app#/">
          HELP
        </a>
        <a className="nav-logout-btn" onClick={handleLogout}>
          LOG OUT
        </a>
      </div>
      <div className="dashboard-container">
        <div className="filter-container">
          <div className="date-picker">
            <div className="start-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  onChange={onChangeStartDate}
                  value={startDate}
                  inputFormat="YYYY/MM/DD"
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        svg: { color },
                        input: { color },
                        label: { color },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="end-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  onChange={onChangeEndDate}
                  value={endDate}
                  inputFormat="YYYY/MM/DD"
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        svg: { color },
                        input: { color },
                        label: { color },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="category-container">
            <FormControl onChange={handleRadio}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{ marginBottom: "-2%" }}
              >
                Search Criteria for Bill of Entry Having:
              </FormLabel>
              <RadioGroup
                style={{ textDecorationColor: "black" }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="receptions"
                  control={<Radio />}
                  label="Reception"
                />
                <FormControlLabel
                  value="delivery"
                  control={<Radio />}
                  label="Delivery"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="category-container" id="search-by">
            <FormControl onChange={handleSearchBy}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{ marginBottom: "-2%" }}
              >
                Advanced Search Per:
              </FormLabel>
              <RadioGroup
                style={{ textDecorationColor: "black" }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="client"
                  control={<Radio />}
                  label="Client"
                />
                <FormControlLabel
                  value="bill_of_entry"
                  control={<Radio />}
                  label="Bill of Entry"
                />
                <FormControlLabel
                  value="hs_code"
                  control={<Radio />}
                  label="HS Code"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="boe-list" id="boe-list">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Bill of entries
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Bill of entries"
                name="boe"
                onChange={handleBoeSelect}
              >
                {boeList?.data.map((boe) => {
                  return (
                    <MenuItem key={boe.id} value={boe.bill_of_entry}>
                      {boe.bill_of_entry}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="client-list" id="client-list">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Clients</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Bill of entries"
                name="client"
                onChange={handleBoeSelect}
              >
                {boeList?.data.map((boe) => {
                  return (
                    <MenuItem key={boe.id} value={boe.client_name}>
                      {boe.client_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="hs-list" id="hs-list">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">HS Codes</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Bill of entries"
                name="hs"
                onChange={handleBoeSelect}
              >
                {boeList?.data.map((boe) => {
                  return (
                    <MenuItem key={boe.id} value={boe.hs_code}>
                      {boe.hs_code}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div class="reset-searchBy" id="reset">
            <button onClick={reset}>Reset</button>
          </div>
          <div className="search-button" onClick={search}>
            <img src={searchIcon} width="100px" className="search-icon" />
          </div>
        </div>
        <div
          className="data-table"
          style={{
            position: "relative",
            top: "190px",
            left: "325px",
            height: "800px",
            width: "80%",
          }}
        >
          <div className="modal">
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {moreDetails && (
                  <DataGridPremium
                    columns={modalCols}
                    components={{ Toolbar: CustomToolbar }}
                    sx={{
                      "&  .MuiDataGrid-cell": {
                        borderRight: `1px solid rgb(224,224,244)`,
                      },
                    }}
                    id="customs-table"
                    rows={moreDetails}
                  />
                )}
              </Box>
            </Modal>
          </div>
          {dataBoe && (
            <DataGridPremium
              components={{ Toolbar: CustomToolbar }}
              columns={columns}
              sx={{
                "&  .MuiDataGrid-cell": {
                  borderRight: `1px solid rgb(224,224,244)`,
                },
              }}
              id="customs-table"
              rows={dataBoe}
              initialState={{
                rowGrouping: { model: ["client_name", "bill_of_entry"] },
              }}
            />
          )}

          {/* On button click => Take bill of entry and display ICs */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
