import "../css/login.css";
import React, { Component, useState } from "react";
import logo from "../components/img/logo.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, Router } from "react-router-dom";
import axios from "axios";

function Login() {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { type, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };
  

  const login = () => {
    const config = {
      withCredentials: true,
    };
    const data = {
      email: state.email,
      password: state.password
    }
    axios
      .post("http://165.232.100.195:5120/api/auth/signin", data, config)
      .then(function(res) {
        window.location.replace(res.data.redirect)
      });
  }

  return (
    <div className="Login">
      <div className="login-container" style={{ background: "white" }}>
        <img className="login-logo" src={logo} />
        <div className="login-form">
          <TextField
            className="login-email"
            id="outlined-name"
            type="email"
            label="Email"
            variant="standard"
            onChange={handleChange}
          />
          <TextField
            className="login-password"
            type="password"
            id="outlined-name"
            label="Password"
            variant="standard"
            onChange={handleChange}
          />
          <Button
            variant="contained"
            onClick={login}
            className="login-button"
            style={{ backgroundColor: "#333192" }}
          >
            LOGIN
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Login;
